import { Callout, getFocusStyle, getTheme, IconButton, mergeStyleSets, Stack, Image, Text, List } from "@fluentui/react"
import { OnlineLanguage } from "@piceasoft/core"
import React from "react"
import { useBoolean, useId } from "@uifabric/react-hooks"


type LanguageSelectorProps = {
    currentLanguage?: string
    onSelected: (key: OnlineLanguage) => void
}


type TLangItem = { key: OnlineLanguage, imgSrc: string }
export const langItems: Array<TLangItem> = [
    { key: "de", imgSrc: "/images/lang/germany.png" }, 
    { key: "en", imgSrc: "/images/lang/usa.png" }, 
    { key: "es", imgSrc: "/images/lang/spain.png" }, 
    { key: "fr", imgSrc: "/images/lang/france.png" }, 
    { key: "it", imgSrc: "/images/lang/italy.png" }, 
    { key: "ru", imgSrc: "/images/lang/russia.png" },
    { key: "pt", imgSrc: "/images/lang/icons8-portugal-48.png" },
    { key: "pt_br", imgSrc: "/images/lang/icons8-brazil-48.png" },
    { key: "tr", imgSrc: "/images/lang/icons8-turkey-48.png" },
    { key: "el", imgSrc: "/images/lang/greece.png" },
    { key: "nl", imgSrc: "/images/lang/netherlands.png" },
    { key: "bg", imgSrc: "/images/lang/bulgaria.png"},
    ]

const LanguageSelector: React.FC<LanguageSelectorProps> = (props) => {
    const [showLanguagesCallout, { toggle: toggleLangCallout }] = useBoolean(false)
    const langButtonClassName = useId('lang-button-class-name')
    const selectedLanguage = props.currentLanguage ? langItems.find( (k:TLangItem) => k.key === props.currentLanguage) : undefined
    const languagIconProps = {
        style: { color: theme.palette.themePrimary, height: selectedLanguage ? 24:16 },
        iconName: undefined === selectedLanguage ? "Globe" : undefined,
        imageProps: selectedLanguage ? {
            src: selectedLanguage.imgSrc,
            height:24,
            width:24,
        } : undefined
    }


    const onRenderLangListCell = (item: TLangItem) => {
        return (
            <Stack onClick={() => {props.onSelected( item.key); toggleLangCallout();
            }} className={classNames.itemCell} horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
                <Image src={item.imgSrc} height={24} width={24}></Image>
                <Text>{item.key}</Text>
            </Stack>
        )
    }

    return (
        <IconButton
            styles={iconButtonStyles}
            iconProps={languagIconProps}
            ariaLabel="Закрыть окно"
            onClick={toggleLangCallout}
            className={langButtonClassName}
        >
            {showLanguagesCallout && (
                <Callout target={`.${langButtonClassName}`}
                    isBeakVisible={false}
                    gapSpace={5}
                    onDismiss={toggleLangCallout}>
                    <List
                        items={langItems}
                        onRenderCell={(item) => item && onRenderLangListCell(item)}
                    />
                </Callout>
            )}
        </IconButton>
    )
}

export default LanguageSelector

const theme = getTheme();

const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};

const classNames = mergeStyleSets({
    itemCell: [
        getFocusStyle(theme, { inset: -1, outlineColor: theme.palette.neutralLight, borderColor: theme.palette.neutralLight }),
        {
            padding: 6,
            boxSizing: 'border-box',
            borderBottom: `1px solid ${theme.semanticColors.bodyDivider}`,
            display: 'flex',
            selectors: {
                '&:hover': { background: theme.palette.neutralLight },
                '&:focus': { background: theme.palette.neutralLight },
            },
        },
    ],
});
