import { NotificationMethods, IAIGradingMessage, IWebDgsStatusMessage } from '@piceasoft/core';
import * as signalR from "@microsoft/signalr";
import { consoleStyles } from './console';
import { IDevice, IModerationMessage, IModuleAIGradingMessage, INotificationMessage, ISkipInspectionMessage } from '@piceasoft/core';
import { IModuleIdentificationMessage } from '@piceasoft/core';
import { IDiagnosticsMessage } from '@piceasoft/core';
import { PiceaApiError } from './picea.errors';

export const initializeNotifications = () => {

    const connection = new signalR.HubConnectionBuilder()
        .withUrl("/notificationHub")
        .configureLogging(signalR.LogLevel.Information)
        .withAutomaticReconnect({
            nextRetryDelayInMilliseconds: retryContext => {
                if (retryContext.elapsedMilliseconds < 30000) {
                    return Math.random() * 10000;
                } else {
                    return null;
                }
            }
        })
        .build();

    const start = async () => {
        try {
            console.debug("%c" + "SIGNALR: connection", consoleStyles.logic);
            console.info({ connection });
            await connection.start().then(() => {
                window.NOTIFICATIONS.onConnected();
            });
        } catch (error) {
            console.debug("%c" + "SIGNALR: connection.start()", consoleStyles.logic);
            console.info({ error });
            setTimeout(start, 5000);
        }
    };

    start();

    connection.on(NotificationMethods.NOTIFICATION, (message: INotificationMessage) => window.NOTIFICATIONS.onNotification(message));
    connection.on(NotificationMethods.PHOTO_MODERATION, (message: INotificationMessage) => window.NOTIFICATIONS.onPhotoModeration(message));
    connection.on(NotificationMethods.SKIP_INSPECTION_BY_SUPERVISOR, (message: ISkipInspectionMessage) => window.NOTIFICATIONS.onSkipInspectionBySupervisor(message));
    connection.on(NotificationMethods.IDENTIFICATION, (message: IDevice) => window.NOTIFICATIONS.onIdentification(message));
    connection.on(NotificationMethods.IDENTIFICATION_MODULE, (message: IModuleIdentificationMessage) => window.NOTIFICATIONS.onModuleIdentification(message));
    connection.on(NotificationMethods.DIAGNOSTICS, (message: IDiagnosticsMessage) => window.NOTIFICATIONS.onDiagnostics(message));
    connection.on(NotificationMethods.AIGRADING_MODULE, (message: IModuleAIGradingMessage) => window.NOTIFICATIONS.onModuleAIGrading(message));
    connection.on(NotificationMethods.WEBDGS_STATUS, (message: IWebDgsStatusMessage) => window.NOTIFICATIONS.onWebDgsStatus(message));
    connection.onclose(window.NOTIFICATIONS.onDisconnected);
    connection.onreconnecting(window.NOTIFICATIONS.onReconnecting)
    connection.onreconnected(window.NOTIFICATIONS.onConnected);
}

export const loadStoredNotifications = (process_id: number) => {
    if (!window.ONLINE_API) return;

    console.debug("loadStoredNotifications");
    
    // load last stored 'notification' for this process_uid from web.
    // we use 'process_id' as the session identifier (virtual session)
    // 'key' is the notification name defined in NotificationMethods


    interface IStoredNt {
        keyname: string,
        onNtf: (...args: any[]) => void
    }

    // list here all supported notifications and corresponding event handlers
    const supportedNotifications: IStoredNt[] = [
        /*{
            keyname: NotificationMethods.IDENTIFICATION,
            onNtf: (message: IDevice) => window.NOTIFICATIONS.onIdentification(message)
        },*/
        {
            keyname: NotificationMethods.IDENTIFICATION_MODULE,
            onNtf: (message: IModuleIdentificationMessage) => window.NOTIFICATIONS.onModuleIdentification(message)
        },
        /*{
            keyname: NotificationMethods.DIAGNOSTICS,
            onNtf: (message: IDiagnosticsMessage) => window.NOTIFICATIONS.onDiagnostics(message)
        },
        {
            keyname: NotificationMethods.AIGRADING_MODULE,
            onNtf: (message: IModuleAIGradingMessage) => window.NOTIFICATIONS.onModuleAIGrading(message)
        },*/
        // Add more notifications as needed
    ]


    interface IGetSessionCallbackData {
        status: number,
        json_data: any
    }

    let config = {
        session_id: "process_id_" + process_id,
        key: "", // will be defined later
    }

    // loop the list and try read data from every key, if found deliver it to corresponding handler and finally remove key from sessionData endpoint.

    supportedNotifications.forEach( (item) => {

        config.key = item.keyname;
        console.info({ config });
    
        window.ONLINE_API.getSessionData(config, (error: PiceaApiError, type: number, data: IGetSessionCallbackData) => {
            //console.debug("%c" + "Picea™ Online Services: ONLINE_API.getSessionData()", consoleStyles.piceaCollback);
            //console.info({ error, data });
    
            if (error || !data) {
                //console.debug("no data for key: " + item.keyname);
                return;
            }
    
            if (data.status !== 0) {
                //console.error("getSessionData failed, status: " + data.status);
                return;
            }
    
            //console.debug("got data for key: " + item.keyname);
            //console.debug(data);

            if (data.json_data && data.json_data != " ") {
                // send actual notification
                item.onNtf(data.json_data);

                // Try clear stored state.
                // And we are not interested of did it succeed or not.
                let config2 = {
                    session_id: "process_id_" + process_id,
                    key: item.keyname,
                    data: " " // it validates that there comes some data, so use " "
                }
                window.ONLINE_API.setSessionData(config2, (error: PiceaApiError, type: number, data: any) => {});
            }
    
        });

    });
}

window.NOTIFICATIONS_HANDLERS = {
    onNotification: (handler: (message: INotificationMessage) => void) => {
        console.debug("%c" + "addEventListener: NOTIFICATIONS.onNotification", consoleStyles.addEventListener);
        const f = (event: Event) => {
            console.debug("%c" + "eventHandler:  NOTIFICATIONS.onNotification", consoleStyles.eventHandler);
            const _event = event as CustomEventInit<INotificationMessage>;
            if (_event && _event.detail) {
                handler(_event.detail);
            } else {
                console.error(event);
            }
        }
        window.addEventListener('NOTIFICATIONS.onNotification', f);
        return () => {
            window.removeEventListener('NOTIFICATIONS.onNotification', f);
            console.debug("%c" + "removeEventListener: NOTIFICATIONS.onNotification", consoleStyles.addEventListener);
        };
    },

    onAIGrading: (handler: (message: IAIGradingMessage) => void) => {
        console.debug("%c" + "addEventListener: NOTIFICATIONS.onAIGrading", consoleStyles.addEventListener);
        const f = (event: Event) => {
            console.debug("%c" + "eventHandler:  NOTIFICATIONS.onAIGrading", consoleStyles.eventHandler);
            const _event = event as CustomEventInit<IAIGradingMessage>;
            if (_event && _event.detail) {
                handler(_event.detail);
            } else {
                console.error(event);
            }
        }
        window.addEventListener('NOTIFICATIONS.onAIGrading', f);
        return () => {
            window.removeEventListener('NOTIFICATIONS.onAIGrading', f);
            console.debug("%c" + "removeEventListener: NOTIFICATIONS.onAIGrading", consoleStyles.addEventListener);
        };
    },

    onDiagnostics: (handler: (message: IDiagnosticsMessage) => void) => {
        console.debug("%c" + "addEventListener: NOTIFICATIONS.onDiagnostics", consoleStyles.addEventListener);
        const f = (event: Event) => {
            console.debug("%c" + "eventHandler:  NOTIFICATIONS.onDiagnostics", consoleStyles.eventHandler);
            const _event = event as CustomEventInit<IDiagnosticsMessage>;
            if (_event && _event.detail) {
                handler(_event.detail);
            } else {
                console.error(event);
            }
        }
        window.addEventListener('NOTIFICATIONS.onDiagnostics', f);
        return () => {
            window.removeEventListener('NOTIFICATIONS.onDiagnostics', f);
            console.debug("%c" + "removeEventListener: NOTIFICATIONS.onDiagnostics", consoleStyles.addEventListener);
        };
    },

    onPhotoModeration: (handler: (message: IModerationMessage) => void) => {
        console.debug("%c" + "addEventListener: NOTIFICATIONS.onPhotoModeration", consoleStyles.addEventListener);
        const f = (event: Event) => {
            console.debug("%c" + "eventHandler:  NOTIFICATIONS.onPhotoModeration", consoleStyles.eventHandler);
            const _event = event as CustomEventInit<IModerationMessage>;
            if (_event && _event.detail) {
                handler(_event.detail);
            } else {
                console.error(event);
            }
        }
        window.addEventListener('NOTIFICATIONS.onPhotoModeration', f);
        return () => {
            window.removeEventListener('NOTIFICATIONS.onPhotoModeration', f);
            console.debug("%c" + "removeEventListener: NOTIFICATIONS.onPhotoModeration", consoleStyles.addEventListener);
        };
    },

    onSkipInspectionBySupervisor: (handler: (message: ISkipInspectionMessage) => void) => {
        console.debug("%c" + "addEventListener: NOTIFICATIONS.onSkipInspectionBySupervisor", consoleStyles.addEventListener);
        const f = (event: Event) => {
            console.debug("%c" + "eventHandler:  NOTIFICATIONS.onSkipInspectionBySupervisor", consoleStyles.eventHandler);
            const _event = event as CustomEventInit<ISkipInspectionMessage>;
            if (_event && _event.detail) {
                handler(_event.detail);
            } else {
                console.error(event);
            }
        }
        window.addEventListener('NOTIFICATIONS.onSkipInspectionBySupervisor', f);
        return () => {
            window.removeEventListener('NOTIFICATIONS.onSkipInspectionBySupervisor', f);
            console.debug("%c" + "removeEventListener: NOTIFICATIONS.onSkipInspectionBySupervisor", consoleStyles.addEventListener);
        };
    },

    onIdentification: (handler: (message: IDevice) => void) => {
        console.debug("%c" + "addEventListener: NOTIFICATIONS.onIdentification", consoleStyles.addEventListener);
        const f = (event: Event) => {
            console.debug("%c" + "eventHandler:  NOTIFICATIONS.onIdentification", consoleStyles.eventHandler);
            const _event = event as CustomEventInit<IDevice>;
            if (_event && _event.detail) {
                handler(_event.detail);
            } else {
                console.error(event);
            }
        }
        window.addEventListener('NOTIFICATIONS.onIdentification', f);
        return () => {
            window.removeEventListener('NOTIFICATIONS.onIdentification', f);
            console.debug("%c" + "removeEventListener: NOTIFICATIONS.onIdentification", consoleStyles.addEventListener);
        };
    },

    onModuleIdentification: (handler: (message: IModuleIdentificationMessage) => void) => {
        console.debug("%c" + "addEventListener: NOTIFICATIONS.onModuleIdentification", consoleStyles.addEventListener);
        const f = (event: Event) => {
            console.debug("%c" + "eventHandler:  NOTIFICATIONS.onModuleIdentification", consoleStyles.eventHandler);
            const _event = event as CustomEventInit<IModuleIdentificationMessage>;
            if (_event && _event.detail) {
                handler(_event.detail);
            } else {
                console.error(event);
            }
        }
        window.addEventListener('NOTIFICATIONS.onModuleIdentification', f);
        return () => {
            window.removeEventListener('NOTIFICATIONS.onModuleIdentification', f);
            console.debug("%c" + "removeEventListener: NOTIFICATIONS.onModuleIdentification", consoleStyles.addEventListener);
        };
    },

    onModuleAIGrading: (handler: (message: IModuleAIGradingMessage) => void) => {
        console.debug("%c" + "addEventListener: NOTIFICATIONS.onModuleAIGrading", consoleStyles.addEventListener);
        const f = (event: Event) => {
            console.debug("%c" + "eventHandler:  NOTIFICATIONS.onModuleAIGrading", consoleStyles.eventHandler);
            const _event = event as CustomEventInit<IModuleAIGradingMessage>;
            if (_event && _event.detail) {
                handler(_event.detail);
            } else {
                console.error(event);
            }
        }
        window.addEventListener('NOTIFICATIONS.onModuleAIGrading', f);
        return () => {
            window.removeEventListener('NOTIFICATIONS.onModuleAIGrading', f);
            console.debug("%c" + "removeEventListener: NOTIFICATIONS.onModuleAIGrading", consoleStyles.addEventListener);
        };
    },
    
    onWebDgsStatus: (handler: (message: IWebDgsStatusMessage) => void) => {
        console.debug("%c" + "addEventListener: NOTIFICATIONS.onWebDgsStatus", consoleStyles.addEventListener);
        const f = (event: Event) => {
            console.debug("%c" + "eventHandler:  NOTIFICATIONS.onWebDgsStatus", consoleStyles.eventHandler);
            const _event = event as CustomEventInit<IWebDgsStatusMessage>;
            if (_event && _event.detail) {
                handler(_event.detail);
            } else {
                console.error(event);
            }
        }
        window.addEventListener('NOTIFICATIONS.onWebDgsStatus', f);
        return () => {
            window.removeEventListener('NOTIFICATIONS.onWebDgsStatus', f);
            console.debug("%c" + "removeEventListener: NOTIFICATIONS.onWebDgsStatus", consoleStyles.addEventListener);
        };
    },

    onConnected: (handler: () => void) => {
        console.debug("%c" + "addEventListener: NOTIFICATIONS.onConnected", consoleStyles.addEventListener);
        const f = (event: Event) => {
            console.debug("%c" + "eventHandler:  NOTIFICATIONS.onConnected", consoleStyles.eventHandler);
            const _event = event as Event;
            if (_event) {
                handler();
            } else {
                console.error(event);
            }
        }
        window.addEventListener('NOTIFICATIONS.onConnected', f);
        return () => {
            window.removeEventListener('NOTIFICATIONS.onConnected', f);
            console.debug("%c" + "removeEventListener: NOTIFICATIONS.onConnected", consoleStyles.addEventListener);
        };
    },

    onDisconnected: (handler: () => void) => {
        console.debug("%c" + "addEventListener: NOTIFICATIONS.onDisconnected", consoleStyles.addEventListener);
        const f = (event: Event) => {
            console.debug("%c" + "eventHandler:  NOTIFICATIONS.onDisconnected", consoleStyles.eventHandler);
            const _event = event as Event;
            if (_event) {
                handler();
            } else {
                console.error(event);
            }
        }
        window.addEventListener('NOTIFICATIONS.onDisconnected', f);
        return () => {
            window.removeEventListener('NOTIFICATIONS.onDisconnected', f);
            console.debug("%c" + "removeEventListener: NOTIFICATIONS.onDisconnected", consoleStyles.addEventListener);
        };
    },

    onReconnecting: (handler: () => void) => {
        console.debug("%c" + "addEventListener: NOTIFICATIONS.onReconnecting", consoleStyles.addEventListener);
        const f = (event: Event) => {
            console.debug("%c" + "eventHandler:  NOTIFICATIONS.onReconnecting", consoleStyles.eventHandler);
            const _event = event as Event;
            if (_event) {
                handler();
            } else {
                console.error(event);
            }
        }
        window.addEventListener('NOTIFICATIONS.onReconnecting', f);
        return () => {
            window.removeEventListener('NOTIFICATIONS.onReconnecting', f);
            console.debug("%c" + "removeEventListener: NOTIFICATIONS.onReconnecting", consoleStyles.addEventListener);
        };
    }
}

window.NOTIFICATIONS = {
    onNotification: (message: INotificationMessage) => {
        console.debug("%c" + "NOTIFICATIONS: onNotification", consoleStyles.notificationsCallback)
        console.info(message);
        window.dispatchEvent(new CustomEvent('NOTIFICATIONS.onNotification', {
            detail: message
        } as CustomEventInit<INotificationMessage>));
    },
    onAIGrading: (message: IAIGradingMessage) => {
        console.debug("%c" + "NOTIFICATIONS: onAIGrading", consoleStyles.notificationsCallback)
        console.info(message);
        window.dispatchEvent(new CustomEvent('NOTIFICATIONS.onAIGrading', {
            detail: message
        } as CustomEventInit<IAIGradingMessage>));
    },
    onDiagnostics: (message: IDiagnosticsMessage) => {
        console.debug("%c" + "NOTIFICATIONS: onDiagnostics", consoleStyles.notificationsCallback)
        console.info(message);
        window.dispatchEvent(new CustomEvent('NOTIFICATIONS.onDiagnostics', {
            detail: message
        } as CustomEventInit<IDiagnosticsMessage>));
    },
    onPhotoModeration: (message: IModerationMessage) => {
        console.debug("%c" + "NOTIFICATIONS: onPhotoModeration", consoleStyles.notificationsCallback)
        console.info(message);
        window.dispatchEvent(new CustomEvent('NOTIFICATIONS.onPhotoModeration', {
            detail: message
        } as CustomEventInit<IModerationMessage>));
    },
    onSkipInspectionBySupervisor: (message: ISkipInspectionMessage) => {
        console.debug("%c" + "NOTIFICATIONS: onSkipInspectionBySupervisor", consoleStyles.notificationsCallback)
        console.info(message);
        window.dispatchEvent(new CustomEvent('NOTIFICATIONS.onSkipInspectionBySupervisor', {
            detail: message
        } as CustomEventInit<ISkipInspectionMessage>));
    },
    onIdentification: (message: IDevice) => {
        console.debug("%c" + "NOTIFICATIONS: onIdentification", consoleStyles.notificationsCallback)
        console.info(message);
        window.dispatchEvent(new CustomEvent('NOTIFICATIONS.onIdentification', {
            detail: message
        } as CustomEventInit<IDevice>));
    },
    onModuleIdentification: (message: IModuleIdentificationMessage) => {
        console.debug("%c" + "NOTIFICATIONS: onModuleIdentification", consoleStyles.notificationsCallback)
        console.info(message);
        window.dispatchEvent(new CustomEvent('NOTIFICATIONS.onModuleIdentification', {
            detail: message
        } as CustomEventInit<IModuleIdentificationMessage>));
    },
    onModuleAIGrading: (message: IModuleAIGradingMessage) => {
        console.debug("%c" + "NOTIFICATIONS: onModuleAIGrading", consoleStyles.notificationsCallback)
        console.info(message);
        window.dispatchEvent(new CustomEvent('NOTIFICATIONS.onModuleAIGrading', {
            detail: message
        } as CustomEventInit<IModuleAIGradingMessage>));
    },
    onWebDgsStatus: (message: IWebDgsStatusMessage) => {
        console.debug("%c" + "NOTIFICATIONS: onWebDgsStatus", consoleStyles.notificationsCallback)
        console.info(message);
        window.dispatchEvent(new CustomEvent('NOTIFICATIONS.onWebDgsStatus', {
            detail: message
        } as CustomEventInit<IWebDgsStatusMessage>));
    },
    onConnected: () => {
        console.debug("%c" + "NOTIFICATIONS: onConnected", consoleStyles.notificationsCallback)
        window.dispatchEvent(new CustomEvent('NOTIFICATIONS.onConnected'));
    },
    onReconnecting: () => {
        console.debug("%c" + "NOTIFICATIONS: onReconnecting", consoleStyles.notificationsCallback)
        window.dispatchEvent(new CustomEvent('NOTIFICATIONS.onReconnecting'));
    },
    onDisconnected: () => {
        console.debug("%c" + "NOTIFICATIONS: onDisconnected", consoleStyles.notificationsCallback)
        window.dispatchEvent(new CustomEvent('NOTIFICATIONS.onDisconnected'));
        initializeNotifications()
    }
}