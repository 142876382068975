import { IWorkflow, OnlineLanguage } from "@piceasoft/core";
import { IEnvironment } from "@piceasoft/core";
import { getApiResponse, IApiResponse } from "./api";
import { endpoints } from "./endpoints";

export const environmentApi = {
    async getEnvironment(): Promise<IApiResponse<IEnvironment>> {
        return await getApiResponse(endpoints.v1.getEnvironmentConfiguration);
    },
    getServiceWorkflow: async (
        id: number,
    ): Promise<IWorkflow | undefined> => {
        let result: IWorkflow | undefined = undefined;
        await fetch(endpoints.v1.service(id.toString()))
            .then(async response => {
                await (response.json() as Promise<{workflow: IWorkflow}>)
                    .then(response => result = response.workflow)
            })
        return result;
    },
    updateLanguage: async (lang: OnlineLanguage): Promise<IApiResponse<undefined>> => {
        let result: IApiResponse<undefined> = { successed: false };
        await fetch(endpoints.v1.profileLanguage(lang), {
            method: 'PATCH',
        })
            .then(async response => {
                await (response.json() as Promise<IApiResponse<undefined>>)
                    .then(response => result = response)
                    .catch(error => result.error = [{ description: error.message }])
            })
            .catch(error => result.error = [{ description: error.message }])
        return result;
    }
}