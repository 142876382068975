import { getTheme, IStackStyles, Separator, Stack, Text } from '@fluentui/react';
import { SharedColors } from '@fluentui/theme';
import * as React from 'react';
import { strings } from '../../../localization/strings';

type TProps = {
    name: string
    explanation?: string
    description?: string
    price?: number
    promoPrice?: number
    secondCurrency?: string
    discount?: number
    currency?: string
}

const isValidPrice = (price?: number): price is number => (price || price === 0) ? true : false

export const Price: React.FC<TProps> = (props) => {
    return (
        <Stack grow tokens={{ padding: 12 }} styles={containerStyles} style={{ opacity: isValidPrice(props.price) ? '1' : '0.5' }}>
            <Stack horizontalAlign="center">
                <Text style={{ fontWeight: "bold", color: SharedColors.gray40, textAlign: "center" }}>{props.name}</Text>
                <Stack verticalFill verticalAlign='center' style={{ width: '100%' }}>
                    <Text block nowrap variant="small" style={{ color: SharedColors.gray20, textAlign: 'center' }}>{props.explanation}</Text>
                </Stack>
            </Stack>
            <Separator />
            <Stack verticalFill horizontalAlign="center" tokens={{ childrenGap: 4 }}>
                {!props.promoPrice && (
                    <Text variant="medium" style={{ color: isValidPrice(props.price) ? SharedColors.orangeYellow20 : SharedColors.gray20 }}>
                        {isValidPrice(props.price) ? (props.price).toLocaleString() : strings.PROCESS.OFFER.NO_OFFER} <Text variant="medium" style={{ color: SharedColors.orangeYellow20 }}>{isValidPrice(props.price) ? props.currency: ""}</Text>
                    </Text>
                ) || (isValidPrice(props.price) && (
                    <Text variant="medium" style={{ color: SharedColors.gray20, textDecoration: "line-through" }}>
                        {props.price.toLocaleString()} <Text variant="medium" style={{ color: SharedColors.gray20, textDecoration: "line-through" }}>{props.currency}</Text>
                    </Text>
                ))}
                {props.discount && (
                    <Text variant="mediumPlus" style={{ color: SharedColors.green10, textDecorationStyle: 'solid' }}>
                        +{props.discount.toLocaleString()} <Text variant="small" style={{ color: SharedColors.green10 }}>{props.secondCurrency}</Text>
                    </Text>
                )}
                {props.promoPrice && (
                    <Text variant="mediumPlus" style={{ color: SharedColors.green10, textDecorationStyle: 'solid' }}>
                        {props.promoPrice.toLocaleString()} <Text variant="medium" style={{ color: SharedColors.green10 }}>{props.secondCurrency}</Text>
                    </Text>
                )}
                <Stack verticalFill verticalAlign='end' >
                    <Text variant="xSmall" style={{ color: SharedColors.gray20, textAlign: "center" }}>{props.description}</Text>
                </Stack>
            </Stack>
        </Stack>
    )
}

const theme = getTheme();
const containerStyles: IStackStyles = { root: { minWidth: 180, maxWidth: 200, backgroundColor: theme.palette.white, boxShadow: theme.effects.elevation8, borderRadius: theme.effects.roundedCorner4 } };
