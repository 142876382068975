import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { initializeIcons } from '@fluentui/react';
import { createBrowserHistory } from 'history';
import configureStore from './core/store/configureStore';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import './assets/css/main.css'
import Startup from './Startup';
import { strings } from './localization/strings';

initializeIcons("/fabric/icons/");

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') as string;
const history = createBrowserHistory({ basename: baseUrl });
const store = configureStore(history);

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <Startup spinnerLabel={strings.SPINNERS.PLEASE_WAIT} />
            </ConnectedRouter>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root'));

declare global {
    interface Window {
        ONLINE_API: any
    }
}
